import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'

Vue.component('loading',{ template: '<div>Loading!</div>'})


Vue.use(Router)

const routes= [
  {
    path: '',
    component: layout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/home')
      }
    ]
  },
  {
    path: '/boutique',
    component: layout,
    children: [
      {
        path: '',
        name: 'shop',
        component: () => import('@/pages/shop'),
        props:true
      },
      {
        path: ':searchResult',
        name: 'shopSearch',
        component: () => import('@/pages/shop')
      },
      {
        path: ':ulidCategory',
        name: 'shopFilter',
        component: () => import('@/pages/shopFilter')
      }
    ]
  },
  {
    path:'/category',
    component: layout,
    children: [
      {
        path:':ulid',
        name: 'category',
        component: () => import ('@/pages/category')
      }
    ]
  },
  {
    path: '/actualites',
    component: layout,
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/pages/news')
      }
    ]
  },
  {
    path: '/contact',
    component: layout,
    children: [
      {
        path: '',
        name: 'contact',
        component: () => import('@/pages/contact')
      }
    ]
  },
  {
    path: '/blog/:ulid',
    component: layout,
    children: [
      {
        path: '',
        name: 'blog',
        component: () => import('@/pages/blog')
      }
    ]
  },
  {
    path: '/panier',
    component: layout,
    children: [
      {
        path: '',
        name: 'cart',
        component: () => import('@/pages/cart')
      }
    ]
  },
  {
    path: '/profil',
    component: layout,
    children: [
      {
        path: '',
        name: 'profil',
        component: () => import('@/pages/profil')
      },
      {
        path: '/modificationprofil',
        name: 'profilUpdate',
        component: () => import ('@/pages/profilUpdate')
      }
    ]
  },
  {
    path: '/facture',
    component: layout,
    children: [
      {
        path: '',
        name: 'mybills',
        component: () => import('@/pages/mybills')
      }
    ]
  },
  {
    path: '/BonDeCommande',
    component: layout,
    children: [
      {
        path: '',
        name: 'mypurchaseorders',
        component: () => import('@/pages/mypurchaseorders')
      }
    ]
  },
  {
    path: '/FAQ',
    component: layout,
    children: [
      {
        path: '',
        name: 'FAQ',
        component: () => import('@/pages/FAQ')
      }
    ]
  },
  {
    path: '/inscription',
    component: layout,
    children: [
      {
        path: '',
        name: 'signin',
        component: () => import('@/pages/signin')
      }
    ]
  },
  {
    path: '/ConfirmationInscription',
    component: layout,
    children: [
      {
        path: '',
        name: 'signinConfirmation',
        component: () => import('@/pages/signinConfirmation')
      }
    ]
  },
  {
    path: '/authentification',
    component: layout,
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/pages/login')
      },
      {
        path: 'motdepasse',
        name: 'forgotPassword',
        component: () => import('@/pages/forgotPassword')
      },
      {
        path: 'code',
        name: 'enterKey',
        component: () => import('@/pages/enterKey')
      },
      {
        path: 'changermotdepasse',
        name: 'changePassword',
        component: () => import ('@/pages/changePassword')
      }
    ]
  },
  {
    path: '/produit/:ulid',
    component: layout,
    children: [
      {
        path: '',
        name: 'product',
        component: () => import('@/pages/product')
      }
    ]
  },
  {
    path: '/test',
    component: layout,
    children: [
      {
        path: '',
        name: 'test',
        component: () => import('@/pages/test')
      }
    ]
  },
  {
    path: '*',
    redirect: '/error-404',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'error-404',
        component: () => import('@/pages/samples/error-pages/error-404')
      }
    ]
  }
]

const router= new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  next()
})
// router.afterEach((to, from) => {
//   console.log('after each TO.name',to.name)
//   console.log('after each FROM.name',from.name);

//   console.log('After each en chargement')
// })

export default router