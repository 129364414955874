<template>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <div class="card">
                <div class="card-body">
                  <div class="auth-form-light text-left p-5">
                      <div class="brand-logo text-center">
                      <img src="../assets/images/logo-beekeeper.png">
                      </div>
                      <h4 class="text-center mt-4">Bienvenue sur Beekeeper974</h4>
                      <h3 class="font-weight-light text-center">Vous devez vous connecter pour accéder à cette page</h3>
                      <div class="text-center mt-4 ">
                        <router-link to='/authentification'>
                          Se connecter
                        </router-link>
                        
                      </div>
                      <div class="text-center mt-4 font-weight-light">
                          Vous n'avez pas de compte? <router-link to="/inscription" class="text-primary">Créer</router-link>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'unauthenticated'
}
</script>