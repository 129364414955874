import Vue from 'vue'
import Vuex from 'vuex'
const axios= require('axios').default

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ulidUser:'',
    pseudo:'',
    firstname:'',
    lastname:'',
    roles: '',
    categories:[],
    products: [],
    allProducts: [],
    isAuthenticated:null,
    darkMode:null,
    isLoaded:false
  },
  getters: {
    cart (state) {
      return state.cart=state.products.length
    }
  },
  mutations: {
    GET_USER (state) {
      state.roles=localStorage.getItem('roles')
      // state.pseudo=localStorage.getItem('pseudo')
      state.isAuthenticated= localStorage.getItem('token')
    },
    GET_CATEGORIES(state, categories) {
      state.categories= categories
    },
    GET_ALL_PRODUCTS(state, products) {
      state.allProducts= products
    },
    SET_PRODUCTS (state) {
      // state.products = products;
      state.products = JSON.parse(localStorage.getItem('products'))
    },
    ADD_PRODUCTS (state, product) {
      // state.cart++;
      state.products.push(product)
      product=''
      const stringifyProduct= JSON.stringify(state.products)
      localStorage.setItem('products', stringifyProduct)
    },
    REMOVE_PRODUCT_TO_CART (state, index) {
      state.products.splice(index, 1);
      // state.cart --
      const stringifyProduct= JSON.stringify(state.products)
      localStorage.setItem('products', stringifyProduct)
    },
    GET_MY_INFOS(state, myinfos) {
      state.ulidUser=myinfos.ulid
      state.pseudo= myinfos.pseudo
      state.firstname= myinfos.firstname
      state.lastname= myinfos.lastname
    },
    LOGOUT(state) {
      state.ulidUser=''
      state.pseudo= ''
      state.isAuthenticated=null
      state.firstname=''
      state.lastname=''
    },
    GET_MODE(state, darkMode) {
      state.darkMode= darkMode
    }
  },
  actions: {
    getCategories (context) {
      axios.get('categories')
      .then(resCategories=>
        {
          context.commit('GET_CATEGORIES', resCategories.data.result)
          console.log({resCategories})
        })
      .catch(errCategories=>console.log(errCategories))
    },

    //add products in cart
    ADD_PRODUCTS(context,product) {
      context.commit ('ADD_PRODUCTS',product)
    },

    //get products in store
    getProducts (context) {
      axios.get('products')
      .then( res=>{
        context.commit('SET_PRODUCTS', res.data.result)
        // console.log('resProduct in Store', res)
      })
      .catch (err => console.log(err))
    },
    getAllProducts (context) {
      axios.get('products')
      .then( res=>{
        context.commit('GET_ALL_PRODUCTS', res.data.result)
        console.log('resProduct in Store', res)
      })
      .catch (err => console.log(err))
    },

    removeProductToCart(context, index) {
      context.commit('REMOVE_PRODUCT_TO_CART', index)
    },
    getMyInfos(context) {
      let trying= localStorage.getItem('token')
      if (!trying) {
        console.log('non authentifié');
      }
      else{
        console.log('authentifié')
        axios.get('myinfos/',{
          headers: {
            'X-AUTH-TOKEN': localStorage.getItem('token')
          }
        })
        .then(resMyInfos => {
          console.log('store info',resMyInfos)
          context.commit ('GET_MY_INFOS', resMyInfos.data.result)
        })
        .catch(errMyInfos => {
          console.log('getMyInfos in store',errMyInfos)
          console.log('errMyInfos data',errMyInfos.response.status)
          if(errMyInfos.response.status=="401"){
            alert('Votre session a été expirée, veuillez vous reconnecter')
            localStorage.removeItem('token')
            localStorage.removeItem('pseudo')
          }else{
            return
          }
        })
      }
    },
    switchMode(context) {
      const theme= localStorage.getItem('darkMode')
      if(!theme){
        console.log('!theme');
        localStorage.setItem('darkMode', 'night')
        context.commit('GET_MODE', localStorage.getItem('darkMode'))
      }
      else if (theme=='day') {
        console.log('theme false');
        localStorage.setItem('darkMode', 'night')
        context.commit('GET_MODE', localStorage.getItem('darkMode'))
      }
      else {
        console.log('theme true');
        localStorage.setItem('darkMode', 'day')
        context.commit('GET_MODE', localStorage.getItem('darkMode'))
      }
    }
  },
  modules: {
  }
})
