import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from './store'
import Snotify, { SnotifyPosition} from 'vue-snotify'
import * as VueAos from 'vue-aos'
import AOS from 'aos';
import 'aos/dist/aos.css'
import axios from 'axios'
import pageLoader from './components/pageLoader.vue'
import unauthenticated from './components/unauthenticated.vue'

AOS.init();
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Snotify, options)
Vue.use(VueAos)
Vue.component('pageLoader', pageLoader)
Vue.component('unauthenticated', unauthenticated)

Vue.config.productionTip = false
axios.defaults.baseURL="https://api-beekeeper.ailem.io";
// axios.defaults.baseURL="http://localhost:8081";
axios.defaults.withCredentials = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
