<template>
    <!-- <div class="page-loader" v-if="!isloaded"> -->
    <div class="page-loader">
        <div class="yellow"></div>
        <div class="red"></div>
		<img src="@/assets/images/logo-beekeeper.png" alt="logo loader">
        <div class="blue"></div>
        <div class="violet"></div>
    </div>
</template>

<script>
export default ({
    data () {
        return {
            // isloaded: false
        }
    },
    mounted () {
        // document.onreadystatechange = () => {
        //     if(document.readyState == "complete") {
        //         this.isloaded = true;
        //     }
        // }
    }
})
</script>

<style lang="scss" scoped>
:root{
	.page-loader {
    display: flex;
    justify-content: center ;
    align-items: center;
    position: fixed;  
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
	}

	div.container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	div > div {
		width: 2vw;
		height: 2vw;
		border-radius: 100%;
		margin: 2vw;
		background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
		animation: bounce 1.5s 0.5s linear infinite;
	}
	.yellow {
		background-color: #0055a4;
	}

	.red {
		background-color: #000;
		animation-delay: 0.1s;
	}

	.blue {
		background-color: #000;
		animation-delay: 0.2s;
	}

	.violet {
		background-color: #ef4135;
		animation-delay: 0.3s;
	}
}
[data-theme="darkMode"] {
	.page-loader {
    display: flex;
    justify-content: center ;
    align-items: center;
    position: fixed;  
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    z-index: 999;
	}

	div.container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	div > div {
		width: 2vw;
		height: 2vw;
		border-radius: 100%;
		margin: 2vw;
		background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
		animation: bounce 1.5s 0.5s linear infinite;
	}
	.yellow {
		background-color: #0055a4;
	}

	.red {
		background-color: rgb(255, 255, 255);
		animation-delay: 0.1s;
	}

	.blue {
		background-color: rgb(255, 255, 255);
		animation-delay: 0.2s;
	}

	.violet {
		background-color: #ef4135;
		animation-delay: 0.3s;
	}
}

@keyframes bounce {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(3px);
	}
	75% {
		filter: blur(3px);
		transform: scale(1.4);
	}
}
</style>
