<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar p-0" toggleable="lg">
    <vue-snotify></vue-snotify>
    <div class="horizontal-menu" >
      <nav class="navbar top-navbar col-lg-12 col-12 p-0">
        <div class="container" >
          <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <router-link class="navbar-brand brand-logo" to="/" >
              <img src="@/assets/images/logo-beekeeper-2.png" alt="logo" v-if="!theme" />
              <img src="@/assets/images/logo-beekeeper-3.png" alt="logo" v-else />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img src="@/assets/images/logo-beekeeper.png" alt="logo" />
            </router-link>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul class="navbar-nav mr-lg-2">
              <li class="nav-item nav-search d-none d-lg-block">
                <div class="input-group">
                  <input list="products" v-model="selectedSearch" type="text" class="form-control" @keyup.enter="search(selectedSearch)" id="navbar-search-input" placeholder="Rechercher un produit" aria-label="search" aria-describedby="search">
                  <datalist id="products">
                    <option :value="product.name" v-for="(product,x) in $store.state.allProducts" :key="x">{{product.name}}</option>
                  </datalist>
                  <div class="input-group-append">
                      <button type="submit" class="input-group-text"><i class="fa fa-search icon-sm" @click="search(selectedSearch)"></i></button>
                  </div>
                </div>
              </li>
            </ul>
            <b-navbar-nav class="navbar-nav-right ml-auto">
              <!-- profil -->
              <b-nav-item-dropdown right class="nav-profile">
                <template slot="button-content">
                  <span v-if="$store.state.pseudo" class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:void(0);" data-toggle="dropdown" aria-expanded="false">
                    <div class="nav-profile-img">
                      <img src="@/assets/images/faces/face1.jpg" alt="image">
                      <span class="availability-status online"></span>
                    </div>
                    <div class="nav-profile-text">
                      <p class="mb-1 text-black">{{$store.state.pseudo}}</p>
                    </div>
                  </span>
                </template>
                <b-dropdown-item class="preview-item">
                  <i class="mdi mdi-cached mr-2 text-success"></i> Activity Log
                </b-dropdown-item>
                <b-dropdown-item class="preview-item">
                  <i class="mdi mdi-logout mr-2 text-primary"></i> Signout
                </b-dropdown-item>
              </b-nav-item-dropdown>
              
              <!-- mode jour/nuit -->
              <b-nav-item class="nav-logout d-none d-lg-flex">
                <span v-if="!theme" @click="switchMode()">🌙</span>
                <span v-else @click="switchMode()">☀️</span>
                <!-- <i class="mdi mdi-theme-light-dark "></i> -->
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch1">
                </div>
              </b-nav-item>

              <!--search -->
              <b-nav-item class="preview-list mobile">
                <i class="fa fa-search" @click="searchBar=!searchBar"></i>
              </b-nav-item>
              
              <!-- Panier -->
              <b-nav-item-dropdown right class="preview-list">
                <template slot="button-content">
                  <div class="nav-link count-indicator dropdown-toggle">
                    <i class="mdi mdi-cart-outline"></i>
                    <div class="badge badge-pill badge-primary">{{cart}}</div>
                  </div>
                </template>
                <h6 class="p-3 mb-0">Panier</h6>
                <div v-for="(product,i) in $store.state.products" :key="i">
                  <b-dropdown-item class="preview-item" id="cartNotification">
                    <div class="preview-thumbnail">
                      <img src="@/assets/images/import/actu-1.jpg" alt="image" class="notification-cart-image">
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">{{product.name}}</h6>
                      <p class="text-gray mb-0">{{product.price}}€</p>
                    </div>
                  </b-dropdown-item>
                </div>
                <h4 class="p-3 mb-0 text-center border-top mb-4">
                  <span class="float-left">
                    {{$store.state.cart}} articles
                  </span>
                  <span class="float-right">
                    Total: {{sumPrice}} €
                  </span>
                </h4>
                <div>
                  <router-link to="/panier">
                    <button type="button" class="btn btn-gradient-info btn-lg btn-block" id="showCart">
                      Voir le panier
                    </button>
                  </router-link>
                </div>
              </b-nav-item-dropdown>

              <!-- logout -->
              <b-nav-item right class="nav-logout d-none d-lg-flex preview-list">
                <i class="mdi mdi-power" @click="logout()"></i>
              </b-nav-item>

            </b-navbar-nav>

            <!-- switch mode toggle -->
            <!-- <b-form-checkbox v-model="checkedMode" name="check-button" switch>
            </b-form-checkbox> -->

            <button class="navbar-toggler navbar-toggler-right align-self-center" type="button" @click="toggleMobileSidebar()">
              <span class="mdi mdi-menu"></span>
            </button>
          </div>
        </div>
      </nav>
      <nav class="bottom-navbar">
        <div class="container">
          <ul class="nav page-navigation">
            <li class="nav-item">
              <a href="javascript:void(0);" class="nav-link">
                <i class="mdi mdi-menu menu-icon"></i>
                <span class="menu-title">Catégories</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu pages">
                <div class="col-group-wrapper row">
                  <div class="col-group col-md-12">
                    <ul class="submenu-item ">
                      <li class="nav-item" v-for="(category,i) in $store.state.categories" :key="i" style="cursor:pointer">
                        <a class="nav-link" @click="showProductCategory(category)">{{category.category}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <!-- accueil -->
            <li class="nav-item">
              <router-link class="nav-link" to="/">
                <i class="mdi mdi-home menu-icon"></i>
                <span class="menu-title">Accueil</span>
              </router-link>
            </li>

            <!-- Boutique -->
            <li class="nav-item">
              <router-link class="nav-link" to="/boutique/">
                <i class="mdi mdi-sale menu-icon"></i>
                <span class="menu-title">Boutique</span>
              </router-link>
            </li>

            <!-- Boutique -->
            <li class="nav-item">
              <router-link class="nav-link" to="/actualites/">
                <i class="mdi mdi-book-open menu-icon"></i>
                <span class="menu-title">Actualités</span>
              </router-link>
            </li>

            <!-- Boutique -->
            <li class="nav-item">
              <router-link class="nav-link" to="/contact/">
                <i class="mdi mdi-phone menu-icon"></i>
                <span class="menu-title">Contact</span>
              </router-link>
            </li>

            <!-- blog -->
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/blog/">
                <i class="mdi mdi-blogger menu-icon"></i>
                <span class="menu-title">Blog</span>
              </router-link>
            </li> -->

            <!-- pages -->
            <li class="nav-item ">
              <a href="javascript:void(0);" class="nav-link">
                <i class="mdi mdi-menu menu-icon"></i>
                <span class="menu-title">Pages</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu pages">
                <div class="col-group-wrapper row">
                  <div class="col-group col-md-12">
                    <ul class="submenu-item ">
                      <li class="nav-item">
                        <router-link class="nav-link" to="/panier/">Panier</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" to="/profil/">Mes informations</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" to="/FAQ/">FAQ</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" to="/inscription/">Inscription</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link v-if="!isAuthenticated" class="nav-link" to="/authentification/">Se connecter</router-link>
                        <router-link v-else class="nav-link" to="/authentification/">Se déconnecter</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </nav>

      <transition name="fade">
        <div v-show="searchBar" class="card mobile">
        <div class="card-body">
          <div class="input-group">
            <input list="products" v-model="selectedSearch" type="text" class="form-control" @keyup.enter="search(selectedSearch)" id="navbar-search-input" placeholder="Rechercher un produit" aria-label="search" aria-describedby="search">
            <datalist id="products">
              <option :value="product.name" v-for="(product,x) in $store.state.allProducts" :key="x">{{product.name}}</option>
            </datalist>
            <div class="input-group-append">
                <button type="submit" class="input-group-text"><i class="fa fa-search icon-sm" @click="search(selectedSearch)"></i></button>
            </div>
          </div>
        </div>
      </div>
      </transition>
      
      
    </div>
  </b-navbar>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
// const axios= require('axios').default
export default {
  name: 'app-header',
  data () {
    return {
      darkMode:false,
      selectedSearch:'',
      checkedMode:false,
      theme:'',
      searchBar:false,
      isAuthenticated:false
    }
  },
  computed: {
    //somme des prix
    ...mapState (['products']),
    ...mapGetters (['cart']),
    sumPrice() {
      let totalPrice=0
      for (const product of this.products) {
        totalPrice= totalPrice +product.price
        console.log('prod in products', product)
      }
      return totalPrice
    },
    allProducts() {
      return this.$store.state.allProducts
    }
  },
  mounted () {
    // vérification si connecté ou pas
    this.myinfos()
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme); // updates the data-theme attribute
    this.theme= localTheme

    
    var navItems = document.querySelectorAll('.horizontal-menu .page-navigation >.nav-item');
    document.querySelectorAll('.horizontal-menu .page-navigation >.nav-item').forEach(function (el) {
      el.addEventListener('click', function () {
        for (var i = 0; i < navItems.length; i++) {
          if (navItems[i] == el) {
            el.classList.toggle('show-submenu')
          }
          else {
            navItems[i].classList.remove("show-submenu");
          }
        }
      })
    });

    // getCategories
     this.$store.dispatch ('getCategories')
     //getProducts
     this.$store.dispatch('getAllProducts')

     //getCart
     if (localStorage.getItem('products')){
       this.$store.commit('SET_PRODUCTS')
       this.$store.commit('SET_CART')
     }
     else{
       return;
     }

     //assigned mode store
       this.$store.commit('GET_MODE', localStorage.getItem('darkMode'))
  },
  methods: {
    toggleMobileSidebar: () => {
      document.querySelector('.bottom-navbar').classList.toggle('header-toggled');
    },
    subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input]
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0 // current path starts with this path string
    })
    },
    handleSCroll () {
      let header = document.querySelector("body");
      if (window.scrollY > 70) {
      header.classList.add('fixed-on-scroll');       
      }
      else {
        header.classList.remove('fixed-on-scroll'); 
      }
    },
    logout() {
      axios.get(`client/logout`)
      .then(resLogout =>{
        console.log({resLogout})
        this.$router.go()
      })
      .catch(errLogout => {
        console.log({errLogout});
      })
      this.$snotify.success('', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        })
    },
    showProductCategory(category) {
      this.$router.replace({
        name: 'category',
        params: {
          ulid: category.ulid,
          categoryName:category.category
          }
      })
      .catch(()=>{})
    },
    switchMode() {
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
    },
    search(value) {
      console.log({value});
      this.$router.push({
        name:'shopSearch',
        params: {
          searchResult: value
        }
      })
    },
    myinfos(){
      axios.get(`myinfos`)
      .then(resMyInfo => {
        this.ploader= false;
        this.isAuthenticated = true
        this.myInfos = resMyInfo.data
        console.log(resMyInfo);
      })
      .catch(errMyInfo=>{
        this.ploader=false;
        this.isAuthenticated = false
        console.log(errMyInfo);
      })
    }
  },
  created () {
    window.addEventListener('scroll', this.handleSCroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleSCroll);
  },
  watch:{
    darkMode(value) {
      localStorage.setItem('darkMode', value)
    },
    $route () {
       document.querySelector('.bottom-navbar').classList.remove('header-toggled');
    },
  } 
}
</script>

<style scoped>
.horizontal-menu .bottom-navbar{
background: linear-gradient(180deg, rgb(89, 89, 89) 0%, rgb(28, 28, 28) 8%, rgb(1, 0, 0) 100%);
border-bottom: 1px solid #322f2f;
}

.notification-cart-image{
  width: 50px !important;
  height: 50px  !important;
  border-radius: none !important;
}

/* taille notification panier */
#showCart{
  min-width: 300px !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#template-header{
  transition: top 0.3s
}
</style>