<template>
  <section class="main-view">
    <pageLoader v-if="ploader" />
    <div class="container-scroller">
      <Header/>
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
            <router-view></router-view>
          <Footer/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
  import Header from "./partials/Header";
  // import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
import PageLoader from '../components/pageLoader.vue';
  export default {
    name: "layout",
    data(){
      return{
        ploader: true
      }
    },
    components: {
      Header,
      // Sidebar,
      Footer,
      PageLoader
    },
    mounted() {
      this.$store.commit('GET_USER')
      this.$store.dispatch('getMyInfos')
      document.onreadystatechange = () => {
            if(document.readyState == "complete") {
                this.ploader = false
            }
        }
    }
  }
</script>