<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <img class="img-fluid" src="../../assets/images/logo-beekeeper-light.png" alt="">
              <div class="d-flex pt-5">
                <div class="social-icons bg-info text-white">
                  <a href="https://www.facebook.com/Beekeeper974-Gardien-dAbeilles-445691442668212">
                  <span class="mdi mdi-facebook"></span>
                  </a>
                </div>
                <div class="social-icons bg-secondary text-white" >
                  <a href="https://www.instagram.com/beekeeper974/">
                  <span class="mdi mdi-instagram"></span>
                  </a>
                </div>
                <!-- <div class="social-icons bg-danger text-white"><span class="mdi mdi-dribbble"></span></div>
                <div class="social-icons bg-info text-white"><span class="mdi mdi-twitter"></span></div> -->
              </div>
            </div>
            <div class="col-lg-3">
              <div class="d-flex">
                <h3 class="text-white">Beekeeper</h3>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/">
                    Accueil
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/boutique/">
                    Boutique
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/actualites">
                  Actualités
                </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">Mentions légales</h6>
              </div>
            </div>
            <div class="col-lg-2">
              <h3 class="text-white">Mon compte</h3>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/authentification">
                    Se connecter
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/panier">
                    Panier
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">CGV</h6>
              </div>
            </div>
            <div class="col-lg-3">
              <h3 class="text-white">Newsletter</h3>
              <p>Recevez nos offres spéciales par mail</p>*
              <div class="mt-3">
                <b-input-group>
                    <b-form-input placeholder="Entrer votre mail"></b-form-input>
                    <b-input-group-text slot="append" class="bg-info text-white">
                      <span><i class="mdi mdi-send"></i></span>
                    </b-input-group-text>
                  </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 <a href="https://ailem.fr/" target="_blank">aile'm </a>. All rights reserved.</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>

<style scoped>
footer {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(42, 42, 42)), color-stop(8%, rgb(28, 28, 28)), to(rgb(1, 0, 0))) !important;
}

.footer a, a:hover {
  color: white;
  text-decoration: none
}
</style>
